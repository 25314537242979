import { mapGetters, mapMutations, mapActions } from "vuex"
import * as _ from "lodash"
import axios from "axios"
import { generateApiUrl } from "@/utils/helpers"
import { Auth } from 'aws-amplify'
import { ApiFactory } from "@/utils/apis/ApiFactory"
const SitelinkAPI = ApiFactory.get("sitelink")
const SteaveAPI = ApiFactory.get("steave")

export default {
  data() {
    return {
      previousTimeout: null,
      menu: [],
      isDarkModeData: false,
    }
  },
  created() {
    this.setDarkModeData()
    setTimeout(() => {
      this.createMenu()
    })
  },
  computed: {
    ...mapGetters({
      isDarkMode: "layoutVars/getIsDarkMode",
      hasBuiltGlobalSidebarMenu: "navigation/getHasBuiltGlobalSidebarMenu",
      selectedAccount: "accounts/getSelectedAccount",
      accountId: "user/getSelectedAccountId",
      user: "user/getUser",
      currentFcmToken: "user/getCurrentFcmToken",
      isSteaveAccount: "accounts/getIsSteaveAccount",
    }),
  },
  methods: {
    ...mapMutations({
      setDarkMode: "layoutVars/setDarkMode",
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
    }),
    ...mapActions({
      setUser: "user/setUser",
      userLocationsAndAccess: 'user/userLocationsAndAccess'
    }),
    setDarkModeData() {
      if (this.isDarkMode == "t") this.isDarkModeData = true
      else this.isDarkModeData = false
    },
    async updateCache() {
      try {
        if (this.isSteaveAccount) {
          await SteaveAPI.updateCache({ accountId: this.selectedAccount.id, body: { user_name: this.user.name, user_email: this.user.email, user_id: this.user.id, account_name: this.selectedAccount.name, fcm_token: this.currentFcmToken } })
        } else {
          await SitelinkAPI.updateCache({ accountId: this.selectedAccount.id, body: { user_name: this.user.name, user_email: this.user.email, user_id: this.user.id, account_name: this.selectedAccount.name, fcm_token: this.currentFcmToken } })
        }
        this.$root.setWebsiteChanges(`Cache Update Started for ${ this.selectedAccount.name }`, null, 'unit_cache')
        this.toastSuccess("Started refreshing website units")
        if (this.user.is_super_admin) {
          this.updateUnitCacheStatus({
            account_id: this.selectedAccount.id,
            status: "started",
          })
        }
      } catch (e) {
        this.toastError("Error refreshing website units")
        this.$rollbar.error('Error updating unit cache', e, { account_id: this.selectedAccount.id })
      }
    },
    async eventClicked(e) {
      console.log("🚀 ~ file: profile-mixin.js ~ line 62 ~ eventClicked ~ e", e)
      if (e === "update_cache") {
        clearTimeout(this.previousTimeout)
        this.previousTimeout = setTimeout(() => {
          this.updateCache()
        }, 200)
      }
      else if (e === "update_units") {
        this.updateCache()
      }
      else if (e === "copy_client_website") {
        // this.$bvModal.show("importer-modal")
        this.$root.$emit("bv::show::modal", "importer-modal")
      }
      else if (e == "color") {
        this.$root.$emit("bv::toggle::modal", "website-color-palette")
      } else if (e == "keyboard") {
        this.$root.$emit("bv::toggle::modal", "keyboard-shortcuts")
      } else if (e == "logout") {
        // await this.delete_cookie("pugg_dash")
        // document.cookie = "pugg_dash=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
        // document.cookie = "pug_dark_mode=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
        var url = new URL(window.origin)
        var cookieUrl = url.host
        if (url.hostname !== "localhost") cookieUrl = "." + url.host.match(/[^\.]*\.[^.]*$/)[0]
        else cookieUrl = url.hostname
        let cookie = `pugg_dash=;expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=${ cookieUrl };path=/;SameSite=None`
        if (cookieUrl !== "localhost") cookie += ";Secure"
        let res = await axios.post(generateApiUrl("pugg"), { pugg: cookie }, { withCredentials: true, headers: { accept: "*/*" } })
        this.setUser(null)
        this.$storage.remove("user")
        this.$store.dispatch('user/setCurrentFcmToken', null)
        firebase.auth().signOut()
        this.$router.push("/login")
      }
    },
    darkMode() {
      let tmp = this.isDarkModeData == true ? "f" : "t"
      this.setCookie("pug_dark_mode", tmp, 365)
      this.setDarkMode(tmp)
      this.setDarkModeData()
      this.createMenu()
    },
    delete_cookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      var expires = "expires=" + d.toUTCString()
      var url = new URL(window.origin)
      var cookieUrl = url.host
      if (url.hostname !== "localhost") cookieUrl = "." + url.host.match(/[^\.]*\.[^.]*$/)[0]
      else cookieUrl = url.hostname
      // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=None;domain=" + cookieUrl + ";HttpOnly;Secure"
      document.cookie = `${ cname }=${ cvalue };${ expires };path=/;SameSite=None`
      // console.log(document.cookie)
    },
    async createMenu() {

      this.menu = [
        {
          type: "change-password",
          icon: "shield",
          text: "Security",
          link: "/my-account/security",
        },
        {
          type: "divider",
        },
        {
          type: "event",
          icon: "log-out",
          text: "Logout",
          link: "",
          event_name: "logout",
        },
      ]
      if (this.$isAllowed("superAdmin")) {
        this.menu.unshift({
          type: "change-password",
          icon: "bell",
          text: "Notifications",
          link: `/my-account/notification-settings`,
        })
        this.menu.unshift({
          type: "change-password",
          icon: "paperclip",
          text: "Onboarding",
          link: `/accounts/onboarding`,
        })
      }
      let userLocations = await this.userLocationsAndAccess()
      if (this.selectedAccount && this.selectedAccount.id && (userLocations.hasFullAccess || this.$isAllowed("superAdmin"))) {
        this.menu.unshift({
          type: "teammates",
          icon: "users",
          text: "Teammates",
          link: `/teammates/${ this.selectedAccount.id }`,
        })
      }
      if (this.selectedAccount && !this.selectedAccount.use_insights_unit_manager) {
        this.menu.unshift({
          type: "divider",
        })
        this.menu.unshift({
          type: "event",
          icon: "refresh-cw",
          text: "Update website units",
          event_name: `update_units`,
        })
      }
      if (this.$isAllowed("superSuperAdmin")) {
        this.menu.unshift({
          type: "divider",
        })

        // Only add menus if global menu is not being used
        if (!this.hasBuiltGlobalSidebarMenu) {
          this.menu.unshift({
            type: "global",
            icon: "globe",
            text: "Global",
            refName: "global",
            children: [
              {
                type: "lists",
                icon: "tag",
                text: "Amenity lists",
                link: "/global/amenities",
              },
              {
                type: "feature",
                icon: "zap",
                text: "Features",
                link: "/global/features",
              },
              {
                type: "form",
                icon: "sidebar",
                text: "Rental form builder",
                link: "/global/form-builder",
              },
              {
                type: "form",
                icon: "send",
                text: "Email Reports",
                link: "/global/email-reports",
              },
              {
                type: "users",
                icon: "bar-chart-2",
                text: "Queries",
                link: "/global/queries",
              },
              {
                type: "users",
                icon: "users",
                text: "Clubhouse",
                link: "/global/clubhouse",
              },
              {
                text: "Client Value",
                link: "/global/client-value",
                icon: "briefcase",
              },
              {
                text: "Component Breakdown",
                link: "/global/component-breakdown",
                icon: "codepen",
              },
              {
                text: "Website Page Search",
                link: "/global/page-search",
                icon: "search",
              },
            ],
          })
          this.menu.unshift({
            type: "event",
            event_name: "copy_client_website",
            text: "Copy Client",
            icon: "copy",
          })
          this.menu.unshift({
            type: "users",
            icon: "user",
            text: "Users",
            link: "/users",
          })
          this.menu.unshift({
            type: "dashboard-global",
            icon: "pie-chart",
            text: "Admin dashboard",
            link: "/accounts/admin-dashboard",
          })
          this.menu.unshift({
            type: "calendar-global",
            icon: "calendar",
            text: "Admin calendar",
            link: "/accounts/admin-calendar",
          })
          this.menu.unshift({
            type: "divider",
          })
        }

        this.menu.unshift({
          icon: "moon",
          type: "dark-mode",
          text: "Dark mode",
          isChecked: this.isDarkModeData,
        })
        this.menu.unshift({
          type: "divider",
        })
        // this.menu.unshift({
        //   event_name: "color",
        //   type: "event",
        //   icon: "sun",
        //   text: "Website Colors",
        // })
        this.menu.unshift({
          event_name: "keyboard",
          type: "event",
          icon: "coffee",
          text: "Keyboard shortcuts",
        })
      } else if (this.$isAllowed("superAdmin")) {
        // Only add menus if global nav is not being used
        if (!this.hasBuiltGlobalSidebarMenu) {
          this.menu.unshift({
            type: "divider",
          })
          this.menu.unshift({
            type: "global",
            icon: "globe",
            text: "Global",
            refName: "global",
            children: [
              {
                type: "lists",
                icon: "tag",
                text: "Amenity lists",
                link: "/global/amenities",
              },
              {
                type: "feature",
                icon: "zap",
                text: "Features",
                link: "/global/features",
              },
              {
                type: "form",
                icon: "sidebar",
                text: "Rental form builder",
                link: "/global/form-builder",
              },
              {
                text: "Client Value",
                link: "/global/client-value",
                icon: "briefcase",
              },
              {
                text: "Component Breakdown",
                link: "/global/component-breakdown",
                icon: "codepen",
              },
              {
                text: "Website Page Search",
                link: "/global/page-search",
                icon: "search",
              },
            ],
          })
          // this.menu.unshift({
          //   type: "event",
          //   event_name: "copy_client_website",
          //   text: "Copy Client",
          //   icon: "copy",
          // })
          this.menu.unshift({
            type: "users",
            icon: "user",
            text: "Users",
            link: "/users",
          })
          this.menu.unshift({
            type: "dashboard-global",
            icon: "pie-chart",
            text: "Admin dashboard",
            link: "/accounts/admin-dashboard",
          })
          this.menu.unshift({
            type: "calendar-global",
            icon: "calendar",
            text: "Admin calendar",
            link: "/accounts/admin-calendar",
          })
        }

        this.menu.unshift({
          type: "divider",
        })
        this.menu.unshift({
          icon: "moon",
          type: "dark-mode",
          text: "Dark mode",
          isChecked: this.isDarkModeData,
        })
        this.menu.unshift({
          type: "divider",
        })
        this.menu.unshift({
          event_name: "color",
          type: "event",
          icon: "sun",
          text: "Website Colors",
        })
        this.menu.unshift({
          event_name: "keyboard",
          type: "event",
          icon: "coffee",
          text: "Keyboard shortcuts",
        })
      } else if (this.$isAllowed("websiteBuilder")) {
        this.menu.unshift({
          type: "divider",
        })
        this.menu.unshift({
          type: "global",
          icon: "globe",
          text: "Global",
          refName: "global",
          children: [
            {
              type: "lists",
              icon: "tag",
              text: "Amenity lists",
              link: "/global/amenities",
            },
            {
              type: "form",
              icon: "sidebar",
              text: "Rental form builder",
              link: "/global/form-builder",
            },
          ],
        })
        this.menu.unshift({
          type: "event",
          event_name: "copy_client_website",
          text: "Copy Client",
          icon: "copy",
        })
        // this.menu.unshift({
        //   type: "users",
        //   icon: "user",
        //   text: "Users",
        //   link: "/users"
        // })
        // this.menu.unshift({
        //   type: "dashboard-global",
        //   icon: "pie-chart",
        //   text: "Admin dashboard",
        //   link: "/accounts/admin-dashboard"
        // })
        // this.menu.unshift({
        //   type: "calendar-global",
        //   icon: "calendar",
        //   text: "Admin calendar",
        //   link: "/accounts/admin-calendar"
        // })
        this.menu.unshift({
          type: "divider",
        })
        this.menu.unshift({
          icon: "moon",
          type: "dark-mode",
          text: "Dark mode",
          isChecked: this.isDarkModeData,
        })
        this.menu.unshift({
          type: "divider",
        })
        this.menu.unshift({
          event_name: "color",
          type: "event",
          icon: "sun",
          text: "Website Colors",
        })
        this.menu.unshift({
          event_name: "keyboard",
          type: "event",
          icon: "coffee",
          text: "Keyboard shortcuts",
        })
      }
    },
  },
}
